const nameMonth = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

const nameWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado"
]

const monthCount = [
    31, // enero
    28, // febrero
    31, // marzo
    30, // abril
    31, // mayo
    30, // junio
    31, // julio
    31, // agosto
    30, // septiembre
    31, // octubre
    30, // noviembre
    31  // diciembre
];

class DateLib {
    static StringDate(date) {
        if(date instanceof Date) {
            return (
                date.getDate().toString().padStart(2, "0") + " de " + nameMonth[date.getMonth()] + ", " + date.getFullYear()
            )
        }
    }

    static StringDateForm(date) {
        if(date instanceof Date) {
            return (
                date.getFullYear().toString() + "-" +
                date.getMonth().toString().padStart(2, "0") + "-" +
                date.getDate().toString().padStart(2, "0")
            )
        }
    }

    static StringDateAllForm(date) {
        let text = DateLib.StringDateForm(date);

        return (
            text + "T" + 
            date.getHours().toString().padStart(2, "0") + ":" +
            date.getMinutes().toString().padStart(2, "0")
        )
    }

    static StringTime(hour, minute) {
        hour += 1;
        let type;

        if(hour >= 12 && hour < 24) {
            type = "PM";
        }
        else {
            type = "AM";
        }
        
        if(hour > 12) {
            hour -= 12;
        }

        return (hour).toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0") + type;
    }

    static addDay(date) {
        date.setDate(date.getDate() + 1);
        return date;
    }

    static removeDay(date) {
        date.setDate(date.getDate() - 1);
        return date;
    }

    static addMonth(date) {
        date.setMonth(date.getMonth() + 1);
        return date
    }

    static removeMonth(date) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }

    static getNameDayOfWeek(date) {
        return nameWeek[date.getDay()];
    }

    static getNameMonthOfYear(date) {
        return nameMonth[date.getMonth()];
    }

    static getDayOfWeek(date) {
        let day = date.getDay() + 1;

        return day > 6 ? 0 : day;
    }

    static getCountDayOfMonth(date) {
        let month = date.getMonth();

        if(month === 1 && DateLib.isLeapYear(date)) return 29; // es febrero y biciesto? retornamos 29
        return monthCount[month]; // en caso que no? retornamos la cantidad segun el mes
    }

    static isLeapYear(date) {  // es año bisiesto?
        let year = date.getFullYear();

        return ( ((year % 4 === 0) && (year % 100 !== 0) ) || (year % 400 === 0));
    }

    static compare(date_a, date_b) {
        if(date_a instanceof Date && date_b instanceof Date) {
            // la instancia actual es menor que el parametro?
            if(
                (date_a.getDate() < date_b.getDate() && date_a.getMonth() <= date_b.getMonth() && date_a.getFullYear() <= date_b.getFullYear()) ||
                (date_a.getMonth() < date_b.getMonth() && date_a.getFullYear() <= date_b.getFullYear()) ||
                (date_a.getFullYear() < date_b.getFullYear())
            ) {
                return -1; // ES MENOR!
            }
            // la instancia actual es mayor que el parametro?
            else if(
                (date_a.getDate() > date_b.getDate() && date_a.getMonth() >= date_b.getMonth() && date_a.getFullYear() >= date_b.getFullYear()) ||
                (date_a.getMonth() > date_b.getMonth() && date_a.getFullYear() >= date_b.getFullYear()) ||
                (date_a.getFullYear() > date_b.getFullYear())
            ) {
                return 1; // ES MAYOR!
            }
            return 0; // ES IGUAL!!!
        }
    }

    static compareAll(date_a, date_b) {
        let result = DateLib.compare(date_a, date_b);

        if(result === 0) {
            // Comparamos la hora
            if(
                ( date_a.getMinutes() > date_b.getMinutes() && date_a.getHours() >= date_b.getHours()) ||
                ( date_a.getHours() > date_b.getHours() )
            ) {
                result = 1; // ES MAYOR!!
            }
            else if(
                ( date_a.getMinutes() < date_b.getMinutes() && date_a.getHours() <= date_b.getHours()) ||
                ( date_a.getHours() < date_b.getHours() )
            ) {
                result = -1; // ES MENOR!
            }
        }

        return result;
    }

    static clone(date) {
        return new Date(date.getTime())
    }
}

export default DateLib;