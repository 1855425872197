// Importaciones Externas
import React from "react";
import {TextField} from "@mui/material";
import {VerifyEmail, VerifyNumber} from "./verify/verifyEmail";
import { InterpretValueDate } from "./verify/verifyDate";
import DateLib from "./myElements/dateLib";

// Componente de Entrada
function InputElement({template})
{
    const [error, setError] = React.useState(0); // Estado del Error

    /* Se verifica y/o se guardan los cambios del componente */
    function VerifyElement(event) {
        let value = event.target.value; // Extraemos el valor

        // Verificacion si es requerido!
        if(template.required === true && !(value.length)) {
            template.error = "Requerido";
            setError(1);
        } 
        // Verificacion si es inferior al valor mínimo
        else if(typeof template.min === "number" && (value.length < template.min)) {
            template.error = `Logitud mínima "${template.min}"`;
            setError(2);
        }
        // Verificacion si es de tipo correo!
        else if(template.type === "email" && !VerifyEmail(value)) {
            template.error = "Correo invalido";
            setError(3);
        }
        // Verificacion si es de tipo texto (SOLO NUMERO)
        else if(template.type === "number" && !VerifyNumber(value)) {
            template.error = "Numero inválido";
            setError(4);
        }
        else {
            // Si llega a este punto! significa que no hubo errores en las comprobaciones
            // por lo tanto, guardamos el valor, y eliminamos la bandera (flag) de error
            template.value = value;
            template.error = undefined;
            setError(0);
        }

        // Verificacion si supera los limite, en caso que sea cierto? se recorta!
        if(template.max && (value.length > template.max)) {
            template.value = event.target.value = value.substr(0,template.max);
        }

        // ejecutamos la funcion onChange del template! solo si existe!
        if(template.onChange) template.onChange(event);
    }

    // Comprobamos si el template, posee un valor! en caso que no? Asignamos un valor por defecto
    if(template.value === undefined) template.value = "";
    // En caso que sea de tipo fecha, y el valor es estrictamente igual a "now"? Asignamos la fecha actual
    else if(template.type === "date") {
        // Extracción de datos!
        let date = InterpretValueDate(template.value);

        template.value = DateLib.StringDateForm(date) // Asignación de fecha
    }


    // Renderizacion del componente
    return (
        <TextField
            sx={{
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                        borderColor: template.color
                    }
                },
                "& label.Mui-focused": {
                    color: template.color
                }
            }}
            name={template.name}
            defaultValue={template.value}
            label={template.display}
            disabled={template.disabled}
            autoFocus={template.focus}
            className={template.className}
            id={template.id}
            onClick={template.onClick}
            onChange={(event) => {VerifyElement(event)}}
            helperText={error ? template.error : template.help}
            placeholder={template.placeholder}
            error={Boolean(error)}
            required={template.required}
            type={template.type === "date" ? "date" : undefined}
            multiline={template.multiline}
            rows={template.rows}
        />
    )
}

export { InputElement }; // Exportacion!