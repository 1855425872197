// Importaciones Externas
import React from "react";
import { FormControl, InputLabel, TextField, Select, MenuItem, FormHelperText} from '@mui/material';

// Componente Select Search (Se le podria considerar como una version de select Extendida!)
function SelectSearchElement({template}) {
    const [textfilter, setTextfilter] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [_value, _setValue] = React.useState(typeof template.value === "number" ? template.value : "");
    const RegExpression = RegExp(textfilter, "i");  // Expresion Regular!

    // Se filtran las opciones segun el valor de textFilter
    const optionsFiltered = template.options.filter((option) => {
        if(option.display === undefined) option.display = option.value;

        return RegExpression.test(option.display);
    })

    // Se actualizan los cambios del elemento
    function VerifyElement(event) {
        let option_filter = optionsFiltered[Number(event.target.value)]; // Extraemos el obj de la opcion filtrada
        
        let index = template.options.findIndex((option) => ( // Buscamos el indice de la opcion en el template
                (option.name === option_filter.name) && (option.value === option_filter.value)
            ))

        _setValue(index);
        let option = template.options[index];

        template.value = option.value;

        if(option.jump !== undefined) template.jump = option.jump;
        if(template.onChange) template.onChange(event); // Se ejecuta onChange! solo si existe!
    }

    // Verificamos si el template no posee un valor? para asignarle uno por defecto!
    if(template.value === undefined) template.value = "";

    function OnChange(event) {
        setTextfilter(event.target.value);
        event.stopPropagation();
    }

    function StopCapture(event) {
        event.stopPropagation();
    }

    // Buscamos el indice del los elementos filtrados para seleccionarlo por defecto!
    let index = -1;
    if(typeof _value === "number" && _value < template.options.length) {
        let option_selected = template.options[_value];
        index = optionsFiltered.findIndex((option) => 
                (option_selected.name === option.name && option_selected.value === option.value)
            )
        if(index === -1 && optionsFiltered.length) index = 0;
    }

    if(index === -1) index = "";
    // Renderizamos el componente

    let sxText = {
        "& .MuiFilledInput-root::after": {
            borderColor: template.color
        }
    };

    let sxSelect = {
        "& label.Mui-focused": {
            color: template.color
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
            borderColor: template.color
        }
    }

    return (
        <FormControl sx={sxSelect}>
            <InputLabel>{template.display}</InputLabel>
            <Select
                name={template.name}
                value={open ? "" : index.toString()}
                label={template.display}
                disabled={template.disabled}
                autoFocus={template.focus}
                className={template.className}
                id={template.id}
                onClick={template.onClick}
                required={template.required}
                onChange={VerifyElement}
                controled="true"
                onOpen={() =>{setOpen(true)}}
                onClose={() => {setOpen(false); setTimeout(() => {setTextfilter("")}, 200)}}
            >
                <TextField sx={sxText} autoComplete="off" value={textfilter} onClickCapture={StopCapture} onKeyDown={StopCapture} onChange={OnChange} variant='filled' placeholder="Filtrar Opciones" className='FMform-element-select-text' size='small' />
                {optionsFiltered.map((option, index) => (<MenuItem key={template.name+option.display+option.value} value={index}>{option.value}</MenuItem>))}
            </Select>
            <FormHelperText />
        </FormControl>
    )
}

export { SelectSearchElement }; // Exportacion!