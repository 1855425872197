// Importaciones Externas!
import React from "react";
import {Slider,FormLabel} from "@mui/material";

// Componente Slider!
function SliderElement({template})
{
    // Se actualizan los cambios del elemento!
    function VerifyElement(event) {
        template.value = Number(event.target.value);
        if(template.onChange) template.onChange(event);
    }

    // Verificamos si el template no posee un valor? para asignarle uno por defecto
    if(typeof template.value !== "number") template.value = 0;

    // Renderizamos el componente!
    return (
        <React.Fragment>
            <FormLabel>{template.display}</FormLabel>
            <Slider
                name={template.name}
                defaultValue={template.value}
                disabled={template.disabled}
                autoFocus={template.focus}
                className={template.className}
                id={template.id}
                onClick={template.onClick}
                onChange={(event) => {VerifyElement(event)}}
                min={template.min}
                max={template.max}
                step={template.step}
                required={template.required}
            />
        </React.Fragment>
    )
}

export { SliderElement }; // Exportacion!