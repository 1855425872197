// Importaciones Externas
import React from "react";
import {Checkbox, FormControlLabel, FormLabel} from "@mui/material";

// Componente CheckBox
function CheckboxElement({template})
{
    /* Se verifica y/o se guardan los cambios del componente */
    function VerifyElement(event, index) {
        // Actualizamos el valor a partir del mismo, invitiendolo
        template.options[index].value = !(template.options[index].value);
        // Ejecutamos la funcion del template, solo si existe!
        if(template.onChange) template.onChange(event);
    }

    // Si el template no posee un valor, le asignamos uno por defecto!
    if(typeof template.value === "boolean") template.value = false;

    // Estilo del Componente Checkbox (Lo utiliza Material UI)
    let sx = {
        color: template.color,
        '&.Mui-checked': {
          color: template.color
        },
    };

    // Creamos un array de renderización de checkboxes
    let checkboxes = template.options.map((option, index) => (
            <FormControlLabel
                key={"$CHECK"+option.name+option.value+index}
                label={option.display}
                control={
                    <Checkbox
                        name={option.name}
                        defaultChecked={option.value}
                        disabled={template.disabled}
                        autoFocus={template.focus}
                        className={template.className}
                        id={template.id}
                        onClick={template.onClick}
                        onChange={(event) => {VerifyElement(event,index)}}
                        required={template.required}
                        sx={sx}
            />}/>
        ));

    // Retornamos la renderizacion de un contenedor con la lista de Checkboxes
    return (
        <div>
            {template.display && <FormLabel>{template.display}</FormLabel>}
            <div className="FMform-checkbox-container">
                {checkboxes}
            </div>
        </div>
    )
}

export { CheckboxElement }; // Exportacion!