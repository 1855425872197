import React from "react";
import {Select, MenuItem, FormControl, InputLabel} from "@mui/material";

function SelectElement({template})
{
    /* Se verifica y/o guardan los cambios del componente */
    function VerifyElement(event) {
        let index = Number(event.target.value);
        let option = template.options[index];

        template.value = option.value;

        if(option.jump !== undefined) template.jump = option.jump;
        if(template.onChange) template.onChange(event); // Se ejecuta onChange! solo si existe!
    }

    // Creamos un array de componentes renderizados de React
    let optionElements = template.options.map((option,index) => {
            // Verificamos que sea una opcion válida
            if(option instanceof Object && typeof option.value === "string") {
                // Verificamos si no hay un valor de display
                if(typeof option.display !== "string") option.display = option.value; // asignamos valor a display

                // Renderizamos una opcion
                return (
                    <MenuItem key={"$SELECT-"+template.name+option.value+option.display} value={index}>
                        {option.display}
                    </MenuItem>
                );
            }
        });

    // Verificamos si no hay un valor en la planilla, para asignar un valor vacio por defecto
    if(typeof template.value !== "string") template.value = "";

    let sxSelect = {
        "& label.Mui-focused": {
            color: template.color
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
            borderColor: template.color
        }
    }

    // Renderizamos La entrada de tipo "select"
    return (
        <FormControl sx={sxSelect}>
            <InputLabel>{template.display}</InputLabel>
            <Select
                name={template.name}
                defaultValue={(template.value !== undefined) ? template.value : ""}
                label={template.display}
                disabled={template.disabled}
                autoFocus={template.focus}
                className={template.className}
                id={template.id}
                onClick={template.onClick}
                onChange={(event) => {VerifyElement(event)}}
                required={template.required}
            >
                {optionElements}
            </Select>
        </FormControl>
    )
}

export { SelectElement }; // Exportación