import React from "react";
import "./cites.component.css";

import DateLib from "../dateLib";
import { TapsItem, TapsComponent } from "../taps.component/taps";

function CiteHeader({display, changeBack, typeFilter, changeRight, changeFilter}) {
    return (
        <div className="MC-cites-header">
            <div className="MC-cites-top">
                <button type="button" onClick={changeBack}>{"<"}</button>
                <div className="MC-cites-title">
                    <span>{DateLib.getNameDayOfWeek(display)}</span>
                    <div className="MC-cites-subtitle">{DateLib.StringDate(display)}</div>
                </div>
                <button type="button" onClick={changeRight}>{">"}</button>
            </div>
            <TapsComponent key={typeFilter} value={typeFilter} onChange={changeFilter}>
                <TapsItem value="0">Mañana</TapsItem>
                <TapsItem value="1">Tarde</TapsItem>
            </TapsComponent>
        </div>
    )
}

function TempButton({onClick, date, selected, children}) {
    function local_click() {
        onClick(date)
    }

    let property = {};

    if(DateLib.compareAll(selected, date) === 0) {
        property.className = "MC-selected";
    }

    return (
        <div className="MC-container">
            <button type="button" {...property} onClick={local_click}>{children}</button>
        </div>
    )
}

function CiteBody({display, lapso, min, max, changeFilter, typeFilter, timeMin, timeMax, changeValue, selected}) {
    let timesElements = [];

    let _min;
    let _max;

    if(typeFilter) { // TARDE
        _min = timeMin < 11 ? 11 : timeMin;
        _max = timeMax;
    }
    else {
        _max = timeMax > 10 ? 10 : timeMax;
        _min = timeMin;
    }

    for(let hour = _min; hour <= _max; hour++) {
        for(let minute = 0; minute < 60; minute += lapso) {
            let local = DateLib.clone(display);

            local.setHours(hour);
            local.setMinutes(minute);

            if(
                (DateLib.compareAll(max, local) !== -1) &&
                (DateLib.compareAll(min, local) !== 1)
            )
            {  
                timesElements.push(
                    <TempButton selected={selected} date={new Date(display.getFullYear(), display.getMonth(), display.getDate(), hour, minute)} onClick={changeValue} key={hour.toString() + minute}>{DateLib.StringTime(hour, minute)}</TempButton>
                )
            }
        }
    }

    if(timesElements.length === 0 && !typeFilter) {
        setTimeout(
            () => {changeFilter(1)},
            0
        )
    }
    
    return (
        <div className="MC-cites-body">
            {timesElements}
        </div>
    )
}

class CitesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.HandleChangeBack = this.HandleChangeBack.bind(this);
        this.HandleChangeRight = this.HandleChangeRight.bind(this);
        this.HandleChangeFilter = this.HandleChangeFilter.bind(this);
        this.HandleChangeValue = this.HandleChangeValue.bind(this);

        let display = props.value;
        let selected = props.value;

        if(!(display instanceof Date)) {
            selected = undefined;
            display = new Date();
        }

        let min = props.min;
        let max = props.max;

        let timeMin = props.timeMin;
        let timeMax = props.timeMax;

        this.lapso = typeof this.props.lapso === "number" ? this.props.lapso : 60;

        if(props.disabled) {
            min = new Date();
            max = new Date();
            timeMin = 23; // Estan entrelazados!
            timeMax = 0;  // por lo que no se puede renderizar ninguna opcion
        }
        else {
            if(!(min instanceof Date)) {
                min = new Date(0);
            }
            
            if(!(max instanceof Date)) {
                max = new Date(2100,10,10);
            }
            
            if(typeof timeMin !== "number") timeMin = 0;
            if(typeof timeMax !== "number") timeMax = 23;
        }

        this.state = {
            display: display,
            selected: selected,
            min: min,
            max: max,
            timeMin: timeMin,
            timeMax: timeMax,
            typeFilter: 0,
            changeValue: this.HandleChangeValue,
            changeFilter: this.HandleChangeFilter,
            changeBack: this.HandleChangeBack,
            changeRight: this.HandleChangeRight
        }
    }

    HandleChangeValue(new_value) {
        // let date = DateLib.clone(this.state.display);
        // let min = DateLib.clone(this.state.min);
        // let max = DateLib.clone(this.state.max);

        this.setState({selected: new_value});

        if(this.props.onChange) this.props.onChange(DateLib.clone(new_value));
    }
    
    HandleChangeFilter(type) {
        this.setState({typeFilter: Number(type)})
    }

    HandleChangeBack() {
        if(DateLib.compare(this.state.min, this.state.display) === -1)
            this.setState(state => ({display: DateLib.removeDay(state.display)}))
    }

    HandleChangeRight() {
        if(DateLib.compare(this.state.max, this.state.display) === 1)
            this.setState(state => ({display: DateLib.addDay(state.display)}))
    }


    render() {
        return (
            <div style={this.props.style} className={`MC-cites${this.props.className ? " " + this.props.className : ""}`} id={this.props.id}>
                <CiteHeader {...this.state} />
                <CiteBody {...this.state} key={this.state.display} lapso={this.lapso} />
                <CiteSelected date={this.state.selected} />
                <input key={"$VALUE"+this.state.selected} type="hidden" name={this.props.name} {...(this.state.selected !== undefined ? {value: (this.state.selected).toISOString()} : {})} />
            </div>
        )
    }
}

function CiteSelected({date}) {
    return (
        <div key={date} className="MC-cites-result">
            {
                date !== undefined ?
                    (DateLib.StringDate(date) + " - " + DateLib.StringTime(date.getHours(), date.getMinutes())) :
                    "Selecciona una Fecha"
            }
        </div>
    )
}

export { CitesComponent }