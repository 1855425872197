// Importaciones Externas
import React from "react";
import { FormElement } from "../FormElement";

function GetClassAlign(align) {
    // eslint-disable-next-line
    switch(align) { 
        case "left":
        case "center":
        case "right":
        case "stretch":
            return " FMform-" + align;
    }
    return "";
}

function InheritProperty(general, template) {
    for(let key in general) {
        if(key !== "children" && template[key] === undefined) {
            template[key] = general[key];
        }
    }
}

// Grupos de Elementos (Permite Organizar y agrupar los Elementos)
function GroupElement({template})
{
    let children; // Array de hijos a renderizar

    // Verificamos si los hijos de la planilla es un array
    if(template.children instanceof Array) {
        // Asignamos un array de Renderizacion de elementos 
        children = template.children.map((child, index) => {
                // Verificamos si el hijo es un objeto válido
                if(child instanceof Object) {
                    // Heredamos todas las propiedades del elemento padre (group) y se la
                    // asignamos a su hijo
                    InheritProperty(template, child);
                    // Renderizamos el hijo
                    return (
                        <div key={"$group-"+index+child.type} className={`FMform-group-element-item${GetClassAlign(child.align)}`}>
                            <FormElement template={child} />
                        </div>
                    )
                }
            });
    }

    // Renderizamos un contenedor, con los hijos renderizados
    return (
        <div className={`FMform-group-element ${template.orientation==="vertical" ? "vert" : "horz"}`}>
            {children}
        </div>
    )
}

export { GroupElement, GetClassAlign }; // Exportaciones