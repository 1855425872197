import DateLib from "../myElements/dateLib";

function InterpretValueDate(stringData) {
    let date;

    if(stringData === "now") {
        date = new Date()
    }
    else if(stringData === "tomorrow") {
        date = DateLib.addDay(new Date()); 
    }
    else if(stringData === "yesterday") {
        date = DateLib.removeDay(new Date());
    }
    else if(typeof stringData === "string") {
        date = new Date(stringData);
    }

    return date;
}

export { InterpretValueDate }