// Importaciones Externas
import React from "react";
import { Button } from "@mui/material";

// Componente de tipo Boton
function ButtonElement({template})
{
    // Prepara una estructura con los estilos del elemento a partir del color resivido
    let style = {backgroundColor: template.color};

    // Renderizamos el elemento
    return (
        <Button
            variant="contained"
            href={template.href}
            disabled={template.disabled}
            autoFocus={template.focus}
            className={template.className}
            id={template.id}
            onClick={template.onClick}
            onChange={template.onChange}
            type={template.type}
            style={style}
        >
            {template.display}
        </Button>
    )
}

export { ButtonElement }; // Exportacion