// Importacion Externas!
import React from "react";
import { InputElement } from "./FormElements/input";
import { SelectElement } from "./FormElements/select";
import { CheckboxElement } from "./FormElements/checkbox";
import { RadioElement } from "./FormElements/radio";
import { SliderElement } from "./FormElements/slider";
import { SwitchElement } from "./FormElements/switch";
import { RatingElement } from "./FormElements/rating";
import { ButtonElement } from "./FormElements/button";
import { GroupElement } from "./FormElements/group";
import { PhoneElement } from "./FormElements/phone";
import { SelectSearchElement } from "./FormElements/search-select";
import "./form.icons.css";
import { CitesElement } from "./FormElements/cites";
import { Calendary } from "./FormElements/calendary";

function LoadMetadata(loadDataName, template, metadata) {
    if(typeof loadDataName === "string" && template instanceof Object && metadata instanceof Array) {
        let index = metadata.findIndex((data) => {
            let result = false;
            
            if(data instanceof Object && data.name === loadDataName && data.content instanceof Array) {
                result = data.content.every((option) => (option instanceof Object));
            }

            return result;
        });

        if(index !== -1) {
            template.options = metadata[index].content;
        }
    }
}

// Generar un tipo de entrada!
// Se renderizará si es un tipo valido
function FormElement({template}) {
    let Render = () => {};
    
    switch(template.type) {
        case "select":
        case "checkbox":
        case "radio":
            if(typeof template.options === "string" && template.metadata instanceof Array) {
                LoadMetadata(template.options, template, template.metadata);
            }
            if(!(template.options instanceof Array)) {
                template.options = [];
            }
            break;
    }

    // Asignacion de color (si no existe?) por defecto!
    if(typeof template.color !== "string") template.color = "dodgerblue"

    switch(template.type) {
        case "input":
        case "email":
        case "number":
        case "date":
            Render = InputElement;
            break;
        case "select-simple":
            Render = SelectElement;
            break;
        case "checkbox":
            Render = CheckboxElement;
            break;
        case "radio":
            Render = RadioElement;
            break;
        case "slider":
            Render = SliderElement;
            break;
        case "switch":
            Render = SwitchElement;
            break;
        case "rating":
            Render = RatingElement;
            break;
        case "submit":
        case "button":
            Render = ButtonElement;
            break;
        case "group":
            Render = GroupElement;
            break;
        case "phone":
            Render = PhoneElement;
            break;
        case "select":
            Render = SelectSearchElement;
            break;
        case "cites":
            Render = CitesElement;
            break;
        case "calendary":
            Render = Calendary;
            break;

        default:
            console.warn(`No existe un elemento de tipo "${template.type}"`);
            break;
    }
    
    return <Render template={template} />; // Salida condicional, Puede o no, generar un renderizado
}

export { FormElement }; // Exportacion