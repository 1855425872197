// Importaciones Externas
import React from "react";
import { CalendaryMonth } from "./myElements/calendary.month/calendary-month";
import DateLib from "./myElements/dateLib";
import { InterpretValueDate } from "./verify/verifyDate";

// Componente de tipo Boton
function Calendary({template})
{
    // Prepara una estructura con los estilos del elemento a partir del color resivido
    let style = {backgroundColor: template.color};

    function onChange(new_value) {
        template.error = "";
        template.value = DateLib.StringDateForm(new_value);
    }

    if(template.required && typeof template.value !== "string") {
        template.error = "requerido*";
    }

    // Renderizamos el elemento
    return (
        <CalendaryMonth 
            name={template.name}
            min={InterpretValueDate(template.min)} 
            max={InterpretValueDate(template.max)}
            value={InterpretValueDate(template.value)}
            disabled={template.disabled}
            // autoFocus={template.focus}
            className={template.className}
            id={template.id}
            onChange={onChange}
            style={style}
        />
    )
}

export { Calendary }; // Exportacion