import React from "react";
import DateLib from "../../dateLib";
import "./month.component.css";

// Nombre de dias de la semana!
const nameDayWeek = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo"
]

// Rellena el array con contenido vacio! segun la cantidad otorgada por el parametro!
function PadBoxEmply(array, count) {
    while(count--) array.push(""); // Crea contenidos vacios!
}

// Renderiza una columna de dias! a partir de un array de contenidos!
function DaysRow({days}) {
    return (
        <tr>
            <td aria-label={nameDayWeek[0]}>{days[0]}</td>
            <td aria-label={nameDayWeek[1]}>{days[1]}</td>
            <td aria-label={nameDayWeek[2]}>{days[2]}</td>
            <td aria-label={nameDayWeek[3]}>{days[3]}</td>
            <td aria-label={nameDayWeek[4]}>{days[4]}</td>
            <td aria-label={nameDayWeek[5]}>{days[5]}</td>
            <td aria-label={nameDayWeek[6]}>{days[6]}</td>
        </tr>
    )
}

// Renderiza el contenido del calendario! (TODOS LOS DIAS DEL MES OTORGADO SEGUN EL AÑO TAMBIEN OTORGADO!)
function DaysBoxes({display, selected, selectDay, min, max}) {
    let daysElements = [];  // Será lo que se renderice!
    let rowDays;
    let daysSpace;
    let dayIndex = 1;
    let dayCount = DateLib.getCountDayOfMonth(display);
    let nRows = 0;
    let propiedades;

    // funcion que se ejecutará al seleccionar un dia!
    function LocalSelectDay(event) {
        selectDay(parseInt(event.target.innerHTML));
    }

    // verificamos que dia es (lunes, martes, miercoles, ...) para rellenar los espacios que
    // permitan alinear los dias en el nombre correspondiente (lunes, martes, etc...)!
    {
        let date_local = DateLib.clone(display);
        date_local.setDate(1); // nos ubicamos en el primer dia del mes!
        
        let dayWeek = date_local.getDay(); // obtenemos el dia de la semana! y sera esa quien nos indique
                                           // cuantos espacios vamos a rellenar!
        rowDays = [];   // son las columnas de los dias de semana!
        daysSpace = 7 - dayWeek; // le decimos a la funcion que los espacios disponibles seran 7 - dayWeek
        PadBoxEmply(rowDays, dayWeek); // rellenamos los espacios para alinear las fechas segun su dia
    }

    // Se preparan las columnas de los dias que se renderizará. Es un array de 7 objetos fecha
    // (Es decir son 7 columnas de los dias ubicados y alineados segun su dia de la semana)
    while(dayCount > 0) {
        if(daysSpace > dayCount) daysSpace = dayCount;
        dayCount -= daysSpace;

        while(daysSpace > 0) {
            let date_local = new Date(display.getFullYear(), display.getMonth(), dayIndex);
            propiedades = {};

            if(
                // Verificamos si es una fecha inferior o mayor!
                DateLib.compare(date_local, min) === -1 || DateLib.compare(date_local, max) === 1
            ) {
                propiedades.disabled = true;
            }
            else if(DateLib.compare(date_local, selected) === 0) 
            {
                propiedades.className = "MC-selected"
            }

            rowDays.push(<button type="button" {...propiedades} onClick={LocalSelectDay}>{dayIndex++}</button>);
            daysSpace--;
        }

        daysElements.push(<DaysRow key={nRows++} days={rowDays} />);

        if(dayCount !== 0) {
            daysSpace = 7;
            rowDays = []
        }
    }

    PadBoxEmply(rowDays, 7 - rowDays.length); // Rellenamos los espacios vacios al final

    return daysElements;
}

function MonthHeadComponent() {
    return (
        <thead> 
            <tr>
                <th aria-label={nameDayWeek[0]}>LUN</th>
                <th aria-label={nameDayWeek[1]}>MAR</th>
                <th aria-label={nameDayWeek[2]}>MIE</th>
                <th aria-label={nameDayWeek[3]}>JUE</th>
                <th aria-label={nameDayWeek[4]}>VIE</th>
                <th aria-label={nameDayWeek[5]}>SAB</th>
                <th aria-label={nameDayWeek[6]}>DOM</th>
            </tr>
        </thead>
    )
}

function MonthBodyComponent(props) {
    return (
        <tbody key={`MONTH-${props.display.getMonth()}-${props.display.getFullYear()}`}>
            <DaysBoxes {...props} />
        </tbody>
    )
}

function MonthComponent(props) {
    return (
        <table className="MC-month">
            <MonthHeadComponent />
            <MonthBodyComponent {...props}/>
        </table>
    )
}

export { MonthComponent }