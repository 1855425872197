import React from "react";
import { FormStruct } from "./form.struct/form.struct"

import arrayHandler from "./JSON/form.cites.json"; // Usado para pruebas

function App() {
  const arrayTemplate = arrayHandler;

  return (
    <div className="display">
      <FormStruct template={arrayTemplate} onFinish={(values) => {console.log(values)}} />
    </div>
  );
}

export default App;
