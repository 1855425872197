// Importaciones Externas!
import React from "react";
import { FormElement } from "./FormElement";
import { GetClassAlign } from "./FormElements/group";

import "./form.page.css";
import "./form.page.effect.css";

// Renderizar Un Error
function DrawError({children}) {
    return <span className="FMform-error">{children}</span>
}

function RecorrerElemento(_elementTemplate, _function) {
    if(_elementTemplate instanceof Object) {
        _function(_elementTemplate)
        
        if(_elementTemplate.type === "group" && _elementTemplate.children instanceof Array) {
            _elementTemplate.children.forEach((_templateChild) => RecorrerElemento(_templateChild, _function))
        }
    }
}


// Componente Columna de Entrada!
function GenerateRowInput({template}) {
    let className = "FMform-row-element" + GetClassAlign(template.align); // className

    // Renderizamos la columna
    return (
        <div className={className}>
            <FormElement template={template} />
        </div>
    )
}

// Componente Página de formulario
class FormPage extends React.Component {
    constructor(props) {
        super(props);
        // props {template, onBack, onSubmit, metadata}

        // Enlazamos las funciones propias!
        this.submit = this.submit.bind(this);
        this.title = this.title.bind(this);
        this.subtitle = this.subtitle.bind(this);
        this.stateError = this.stateError.bind(this);

        // Almacenamos la planilla!
        let template = this.template = props.template;

        if(template === undefined) return;

        // Establecemos el estado inicial!
        this.state = {
            error: false
        };

        // Inicializacion del estilo propio
        this.style = {}; // Objeto Vacio!

        // Verificacion si la planilla posee un color!
        if(typeof template.color === "string") {
            this.style.color = template.color; // Asignacion del color
        }

        // Inicializacion de los botones inferiores!
        // Generacion de una planilla de un elemento del formulario (group)
        // Aqui es donde se insertarán los botones next y back mas adelante!
        // dentro del mismo constructor!
        this.rowButtons = { type: "group", color: template.color, children:[]};

        // Verificacion si existe el elemento (button) back!
        // Puede ser un objeto (PLANILLA DE ELEMENTO) o una cadena (será el display del botton)
        if(template.back instanceof Object) {
            let back = {...template.back}; // Estraemos la planilla de back!

            // hacemos que sea de tipo button
            back.type = "button";

            if(props.onBack === "noBack") back.disabled = true; // deshabilitamos si no hay posibilidad de regresar!
            else back.onClick = props.onBack; // Asignamos funcion! (Se puede regresar un paso atras!)
            
            if(typeof back.display !== "string") back.display = "Regresar"; // Display del botton por defecto!
            this.rowButtons.children.push(back); // Se incrusta la planilla back en la planilla rowButton
        }
        else if(typeof template.back === "string") {
            let back = {type:"button",display:template.back}; // Se genera una planilla de button, con el display
                                                            // Indicado inplicitamente!
            
            if(props.onBack === "noBack") back.disabled = true; // Deshabilitamos si no hay posibilidad de regresar!
            else back.onClick = props.onBack; // Asignamos funcion! (Se puede regresar un paso atras!)
            this.rowButtons.children.push(back); // Se incrusta la planilla back en la planilla rowButton
        }

        // Verificacion si existe el elemento (button) next!
        // Puede ser un objeto (PLANILLA DE ELEMENTO) o una cadena (será el display del botton)
        if(template.next instanceof Object) {
            let next = {...template.next}; // Extraemos planilla
            next.type = "submit"; // Establecemos que es un tipo submit
            
            if(typeof next.align !== "string") {
                next.align = "right";
            }
            if(typeof next.display !== "string") next.display = "Siguiente"; // Asignamos Siguiente como valor por defecto
            this.rowButtons.children.push(next); // Incrustamos planilla next a la planilla rowButtons
        }
        else if(typeof template.next === "string") {
            let next = {type:"submit",display:template.next, align:"right"}; // Se genera una planilla de button, con el display
                                                            // Indicado inplicitamente!
            this.rowButtons.children.push(next); // Incrustamos planilla next a la planilla rowButtons
        }

        // Renderizamos la planilla rowButton Solo si tiene hijos (next || back)
        this.rowButtons = (this.rowButtons.children.length > 0) && (<GenerateRowInput template={this.rowButtons} />);
    }

    // Funcion que se ejecutará cuando el usuario haga indicio que quiere enviar el formulario
    submit(event) {
        let template = this.template;
        event.preventDefault(); // Prevenir la accion por defecto
        
        let error = false;

        try {
            RecorrerElemento(template, (template) => {
                if(template.error && template.error.length > 0) throw("ERROR");
            })
        }
        catch(ERR) {
            error = true;
        }

        if(!error)  {// Verificamos si no hay algun error en la planilla
            this.setState({error: false}); // Eliminamos la bandera de error (flag)
            
            // Verificamos si se debe ejecutar un efecto antes de enviar el formulario!
            if(typeof template.effect === "string") {
                
                event.target.className += " play-effect effect--"+template.effect;
                setTimeout(
                    () => {if(this.props.onSubmit) this.props.onSubmit(event)}, // Function!
                    500 // milisegundos
                );
            }
            else if(this.props.onSubmit) this.props.onSubmit(event);
        }
        else {
            this.setState({error: true}); // Ooopss! si pasa por aqui significa que hay un campo invalido!
        }
    }

    title() {
        if(this.template.title) {
            return (
                <div style={this.style} className="FMform-page-title">
                    {this.template.title}
                </div>
            )
        }
    }

    subtitle() {
        if(this.template.subtitle) {
            return (
                <div className="FMform-page-subtitle">
                    {this.template.subtitle}
                </div>
            )
        }
    }

    stateError() {
        if(this.state.error) {
            return (
                <div className="FMform-text-error">
                    El campo introducido es inválido!
                </div>
            )
        }
    } 
    
    render() {
        // Verificacion de la estructura de la planilla es inválida
        if(!(this.template instanceof Object)) return <DrawError>Planilla de página del formulario Invalida</DrawError>;

        if(this.props.metadata instanceof Array) {
            if(this.template.metadata instanceof Array) 
                this.template.metadata.concat(this.props.metadata)
            else 
                this.template.metadata = this.props.metadata
        }
        
        // Renderizacion de la página del formulario
        return (
            <form className={`FMform-page${(typeof this.template.effect === "string") ? " form-effect" : ""}${(typeof this.template.frame === "string") ? (" frame--" + this.template.frame) : ""}`} key={"$FORM"+this.template.caption} onSubmit={this.submit}>
                <this.title />
                <this.subtitle />
                <GenerateRowInput template={this.template} />
                <this.stateError />
                {this.rowButtons}
            </form>
        )
    }
}

export {FormPage, DrawError, RecorrerElemento}; // Exportacion!