// Importaciones Externas!
import React from "react";
import {RadioGroup, FormControlLabel, FormLabel, Radio} from "@mui/material";

// Componente Grupo de radioButtons
class RadioElement extends React.Component {
    render() {
        let template = this.props.template;

        // Se Verifica y/o se guardan los cambios actuales del elemento
        const VerifyElement = function(event) {
            let index = Number(event.target.value);
            let option = template.options[index];

            template.value = option.value;

            if(option.jump !== undefined) template.jump = option.jump;
            if(template.onChange) template.onChange(event); // Se ejecuta onChange! solo si existe!

            this.forceUpdate()
        }.bind(this)

        let index = template.options.findIndex(option => (option.value === template.value));

        // Se asigna un array de renderizaciones, de las distintas opciones (radio button)
        let elementsRadio = template.options.map((option, index) => {
                // Verificamos si posee un valor válido
                if(typeof option.value === "string") {
                    // Asignamos el valor del valor, a la visualizacion (si no está definida!)
                    if(typeof option.display !== "string") option.display = option.value;

                    // Renderizamos correctamente el radio button
                    return (
                        <FormControlLabel 
                            key={"$RADIO-"+option.name+option.display+index} 
                            value={index} 
                            label={option.display} 
                            control={<Radio sx={{color: template.color,'&.Mui-checked':{color: template.color}}} required={template.required} />} 
                        />
                    );
                }
            })

        // Verificamos si no hay ningun valor en la planilla ? para asignarle uno por defecto
        if(typeof template.value !== "string") template.value = "";

        // Renderizamos el contenedor de radio buttons, con su respectivos radio buttons!
        return (
            <div>
                <FormLabel>{template.display}</FormLabel>
                <RadioGroup
                    name={template.name}
                    value={index}
                    label={template.display}
                    disabled={template.disabled}
                    autoFocus={template.focus}
                    className={template.className}
                    id={template.id}
                    onClick={template.onClick}
                    onChange={VerifyElement}
                    controlled="controlled"
                >
                    {elementsRadio}
                </RadioGroup>
            </div>
        )
    }
}

export { RadioElement }; // Exportacion General