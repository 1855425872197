// Importaciones Externas!
import React from "react";
import {Switch, FormControlLabel} from "@mui/material";

// Componente Switch
function SwitchElement({template})
{
    // Se actualizan los cambios del elemento!
    function VerifyElement(event) {
        template.value = !(template.value);

        if(template.onChange) template.onChange(event);
    }

    // Verificamos si el template no posee un valor? para asignarle uno por defecto
    if(template.value === undefined) template.value = false;

    // Renderizamos el elemento
    return (
        <FormControlLabel 
            className="FMform-switch-label" 
            label={template.display} 
            labelPlacement="start"
            control={
                <Switch
                    name={template.name}
                    defaultChecked={template.value}
                    label={template.display}
                    disabled={template.disabled}
                    autoFocus={template.focus}
                    className={template.className}
                    id={template.id}
                    onClick={template.onClick}
                    onChange={(event) => {VerifyElement(event)}}
                    required={template.required}
                />}
        />
    )
}

export { SwitchElement }; // Exportaciones Generales!