import React from "react";
import tapContext from "./context";

class TapsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        if(props.value !== undefined) this.state.value = props.value.toString();

        this.HandleOnClick = this.HandleOnClick.bind(this);
    }
    
    HandleOnClick(event) {
        if(event.target.name === "MC-TapItem" && event.target.value) {
            this.setState({value: event.target.value})

            if(this.props.onChange) this.props.onChange(event.target.value);
        }
    }

    render() {
        return (
            <tapContext.Provider value={this.state.value}>
                <div className="MC-taps" onClick={this.HandleOnClick}>
                    {this.props.children}
                    <input name={this.props.name} type="hidden" />
                </div>
            </tapContext.Provider>
        )
    }
}

export default TapsComponent;
