// Importaciones Externas
import React from "react";
import {CitesComponent} from "./myElements/cites.component/cites.component";
import { InterpretValueDate } from "./verify/verifyDate";

// Componente de tipo Boton
function CitesElement({template})
{
    // Prepara una estructura con los estilos del elemento a partir del color resivido
    let style = {"--color":template.color};

    // Actualizacion de los datos
    function onChange(new_value) {
        template.value = new_value.toISOString();
        template.error = "";

        if(template.onChange) template.onChange(new_value);
    }

    if(template.required && typeof template.value !== "string") {
        template.error = "Requerido*";
    }

    // Renderizamos el elemento
    return (
        <CitesComponent
            name={template.name}
            value={InterpretValueDate(template.value)}
            min={InterpretValueDate(template.min)}
            max={InterpretValueDate(template.max)}
            timeMin={template.timeMin && Number(template.timeMin)}
            timeMax={template.timeMax && Number(template.timeMax)}
            disabled={template.disabled}
            className={template.className}
            id={template.id}
            onChange={onChange}
            style={style}
            lapso={template.lapse}
        >
            {template.display}
        </CitesComponent>
    )
}

export { CitesElement }; // Exportacion