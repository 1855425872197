// Importaciones Externas!
import React from "react";
import {Rating, FormLabel} from "@mui/material";

// Componente de Calificacion / Valoracion
function RatingElement({template})
{
    // Actualizamos los cambios del elemento
    function VerifyElement(event) {
        template.value = Number(event.target.value);
        if(template.onChange) template.onChange(event);
    }

    // Verificamos si no hay ningun valor en la planilla? para asignar uno por defecto
    if(typeof template.value !== "number") template.value = 0;

    // Renderizamos el componente
    return (
        <div style={{width:"100%",justifyContent:"space-between",display:"flex"}}>
            <FormLabel>{template.display}</FormLabel>
            <Rating
                name={template.name}
                defaultValue={template.value}
                label={template.display}
                disabled={template.disabled}
                autoFocus={template.focus}
                className={template.className}
                id={template.id}
                onClick={template.onClick}
                onChange={(event) => {VerifyElement(event)}}
                required={template.required}
                size="large"
            />
        </div>
    )
}

export { RatingElement }; // Exportacion