// Importaciones Externas
import React from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

// Componente de entrada (Numero Celular)
function PhoneElement({template}) {
  const [phone, setPhone] = React.useState(template.value); // Estado del valor actual (celular)

  // Se compara y se guarda los cambios actuales del elemento
  const VerifyValue = (newPhone) => {
    if(matchIsValidTel(newPhone)) template.error = undefined; // undefined significa que no hay error alguno
    else template.error = "Numero Invalido"; // Error detectado!

    template.value = newPhone;
    setPhone(newPhone); // Asignamos el nuevo valor
  }

  let sx = {
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: template.color
        }
    },
    "& label.Mui-focused": {
        color: template.color
    }
}

  // Renderización del elemento
  return (
    <MuiTelInput sx={sx} value={template.value} onChange={VerifyValue} continents={['NA','SA']} />
  )
}

export { PhoneElement }; // Exportacion!