import React from "react";
import { FormPage, RecorrerElemento } from "../form.page/form.page";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "./form.struct.css";

function DrawFinish({/*values, //parametro permitido*/ template}) {
    let style = {};

    if(typeof template.color === "string") style.color = template.color;
    
    return (
        <div className="FMform-page FMform-finishing">
            <div className="FMform-page-title" style={style}><CheckCircleIcon className="FMform-page-ok" style={{float:"right"}}/>Finalizacion!</div>
            <div className="FMform-page-subtitle" style={{padding:"20px 0px"}}>Eso ha sido todo! Ya puedes cerrar esta página!</div>
        </div>
    )
}

class FormStruct extends React.Component {
    constructor(props) {
        super(props);
        
        // Vinculacion de las funciones propias!
        this.nextPage = this.nextPage.bind(this);
        this.VerifyTemplate = this.VerifyTemplate.bind(this);
        this.backPage = this.backPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.finish = this.finish.bind(this);
        this.clonePage = this.clonePage.bind(this);
        this.loadGeneral = this.loadGeneral.bind(this);
        this.background = this.background.bind(this);
        this.SaveHistory = this.SaveHistory.bind(this);
        this.RestoreHistory = this.RestoreHistory.bind(this);
        this.EvalJump = this.EvalJump.bind(this);
        this.findKeyOnPages = this.findKeyOnPages.bind(this);
        this.getValues = this.getValues.bind(this);
        this.InitLocalPage = this.InitLocalPage.bind(this);

        // Verificamos si la planilla es válida?
        if(this.VerifyTemplate()) {

            this.template = props.template;
            this.pages = this.template.pages;
            this.indexMax = this.pages.length - 1;
            this.history = props.history || [];
            this.typeValues = props.typeValues;
            this.values = props.values || (this.typeValues === "object" ? {} : []);
            
            this.jumpExtern = typeof props.jumpExtern === "function" ? props.jumpExtern : () => {};
            
            let index = ( props.defaultIndex ? props.defaultIndex : 0 );

            // Verificamos si se decea empezar desde un nombre del elemento, en espesifico
            if(typeof props.startWith === "string") {
                let index_key = this.findKeyOnPages("name", props.startWith);

                if(index_key !== -1) index = index_key;
            }
            
            
            // Estado por defecto!
            this.state = {
                index,
                finished: (index > this.indexMax)
            }

            if(this.state.finished && props.onFinish) props.onFinish(this.values);
        }
    }

    getValues() {
        let values = this.values;

        const GetValueTemplate_typemap = function(_template) {
            if(typeof _template.name === "string" && typeof _template.value !== undefined)
            {
                let new_value = {key: _template.name, value: _template.value};

                let indexValue = values.findIndex(value => (value.key === new_value.key));

                if(indexValue !== -1) {
                    values[indexValue] = new_value;
                }
                else {
                    values.push(new_value)
                }
            }
        }.bind(this)

        const GetValueTemplate_typeobject = function(_template) {
            if(typeof _template.name === "string" && typeof _template.value !== undefined) {
                values[_template.name] = _template.value;
            }
        }
        
        RecorrerElemento(
            this.page_local, 
            this.typeValues === "object" ? GetValueTemplate_typeobject : GetValueTemplate_typemap
        )
    }

    findKeyOnPages(key, value) {
        return this.pages.findIndex((template) => {
            return (template[key] !== undefined && ( value === undefined || template[key] === value))
        })
    }

    EvalJump() {
        let jump = this.page_local.jump;

        const jumpIndex = function(index) {
            this.SaveHistory()
            this.setState({index})

            if(index > this.indexMax) {
                this.finish()
            }
        }.bind(this)

        const jumpDefault = function() {
            jumpIndex(this.state.index + 1) // Salto por defecto
        }.bind(this)

        if(jump === "finish") {
            this.finish();
            return;
        }
        else if(typeof jump === "number") {
            jumpIndex(jump);
        }
        else if(this.jumpExtern(jump, this.state.index)) {
            console.log("SALTO EXTERNO ENCONTRADO!");
        }
        else if(typeof jump === "string") {
            let indexOfTemplate = this.findKeyOnPages("name", jump);

            if(indexOfTemplate === -1) indexOfTemplate = this.findKeyOnPages("id", jump);

            if(indexOfTemplate !== -1) {
                jumpIndex(indexOfTemplate);
            }
            else {
                jumpDefault();
            }
        }
        else {
            jumpDefault();
        }
    }

    VerifyTemplate() {
        return (this.props.template instanceof Object && this.props.template.pages instanceof Array)
    }

    SaveHistory() {
        this.history.push(this.state.index);
    }

    RestoreHistory() {
        if(this.history.length > 0) {
            let value_history = this.history.pop();

            if(typeof value_history === "function") {
                value_history = value_history(this.state.index);
            }
            
            this.setState({index: value_history})
        }
    }

    nextPage() {
        this.getValues()
        this.EvalJump()
    }

    backPage() {
        this.RestoreHistory()
    }

    finish() {
        this.setState({finished: true})
        
        if(this.props.onFinish) this.props.onFinish(this.values)
    }

    clonePage(template_old) {
        if(template_old instanceof Object)
            return (JSON.parse(JSON.stringify(template_old)));
        else
            return {}
    }

    loadGeneral(template) {
        let general = this.template.general;

        if(general instanceof Object && template instanceof Object) {
            // Recorremos todo el objeto general y anexamos las propiedades faltantes!
            for(let key in general) {
                if(template[key] === undefined) {
                    template[key] = general[key];
                }
            }
        }
        return template;
    }

    background({children}) {
        let style = {};
        
        if(this.props.noBackground) {
            return (
                <React.Fragment>{children}</React.Fragment>
            )
        }
        else {
            if(this.page_local instanceof Object) {
                if(typeof this.page_local.background === "string") {
                    style.backgroundImage = `url(${this.page_local.background})`;
                }
                else if(typeof this.page_local.color === "string") {
                    style.backgroundImage = `linear-gradient(${this.page_local.color}, white)`;
                }
                else {
                    style.backgroundImage = `linear-gradient(dodgerblue, white)`;
                }
            } 
            
            return (
                <div style={style} className="FMform-struct-background">
                    {children}
                </div>
            )
        }
    }

    InitLocalPage() {
        this.page_local = this.loadGeneral(
            this.clonePage( this.template.pages[this.state.index] )
        );

        const LoadValues = function(_template) {
            if(_template instanceof Object) {
                let index = this.values.findIndex(value => (value.key === _template.name))

                if(index !== -1) {
                    _template.value = this.values[index].value;
                }
            }
        }.bind(this);

        RecorrerElemento(this.page_local, LoadValues);
    }

    render() {
        // Verificamos si la planilla es válida!
        if(this.VerifyTemplate(this.template)) {
            this.InitLocalPage();
            
            if(this.state.finished) {
                return (
                    <this.background>
                        <DrawFinish template={this.page_local} />
                    </this.background>
                )
            }
            else {
                return (
                    <this.background>
                        <FormPage metadata={this.props.metadata} key={this.state.index} onBack={this.backPage} onSubmit={this.nextPage} template={this.page_local} />
                    </this.background>
                )
            }
        }
        else {
            // en caso que no?
            return (
                <this.background>
                    <div className="FMform-error">PLANILLA INVALIDA!</div>
                </this.background>
            )
        }
    }
}

export { FormStruct, DrawFinish }