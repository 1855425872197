import React from "react";

import tapContext from "./context";

class TapsItem extends React.Component {
    render() {
        return (
            <button type="button" className={(this.context === this.props.value ? "MC-selected" : "")} name="MC-TapItem" value={this.props.value}>{this.props.children}</button>
        )
    }

    static contextType = tapContext;
}

export default TapsItem;