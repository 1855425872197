import React from "react";
import { MonthComponent } from "./month/month.component";

import "./calendary-month.css"
import DateLib from "../dateLib";

class CalendaryMonth extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeLeft = this.handleChangeLeft.bind(this);
        this.handleChangeRight = this.handleChangeRight.bind(this);
        this.handleSelectDay = this.handleSelectDay.bind(this);
        this.CalendaryTop = this.CalendaryTop.bind(this);
        
        {
            let display = props.value;
            let selected = props.value;

            if(!(display instanceof Date)) {
                display = new Date();
                selected = undefined;
            }

            this.state = {
                display,    // se asignan
                selected    // al objeto state
            }
        }

        {
            if(this.props.disabled) {
                this.min = new Date();
                this.max = new Date();
                DateLib.removeDay(this.max);
            }
            else {
                if(props.min instanceof Date) {
                    this.min = props.min;
                }
                else this.min = new Date(0);
    
                if(props.max instanceof Date) {
                    this.max = props.max;
                }
                else this.max = new Date(2100,11,31);
            }
        }
    }

    handleSelectDay(day) {
        let new_value = DateLib.clone(this.state.display);
        new_value.setDate(day);

        this.setState({selected: new_value})
        if(this.props.onChange !== undefined) this.props.onChange(DateLib.clone(new_value));
        
    }

    handleChangeLeft() {
        let display = this.state.display;
        DateLib.removeMonth(display);

        this.setState({display: DateLib.clone(display)})
    }

    handleChangeRight() {
        let display = this.state.display;
        DateLib.addMonth(display);

        this.setState({display: DateLib.clone(display)})
    }

    CalendaryTop() {
        let leftButton = {};
        let rightButton = {};

        {
            let date_other = DateLib.clone(this.min);
            let date_month = DateLib.clone(this.state.display);
            date_month.setDate(1);
            date_other.setDate(1);
            
            // verificamos si puede dar un paso atras!
            if(DateLib.compare(date_month, date_other) === 1) {
                leftButton.onClick = this.handleChangeLeft;
            }
            else {
                leftButton.disabled = true;
            }

            date_other = DateLib.clone(this.max);
            date_other.setDate(1);

            // verificamos si se puede dar un paso adelante!
            if(DateLib.compare(date_month, date_other) === -1) {
                rightButton.onClick = this.handleChangeRight;
            }
            else {
                rightButton.disabled = true;
            }
        }

        return (
            <div className="MC-top">
                <div className="MC-top-box">
                    <button type="button" {...leftButton}>{"<"}</button>
                    <span className="MC-top-text">{DateLib.getNameMonthOfYear(this.state.display)} - {this.state.display.getFullYear()}</span>
                    <button type="button" {...rightButton}>{">"}</button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id={this.props.id} className={`MC-calendary-month${this.props.className ? (" " + this.props.className) : ""}`} style={this.props.style}>
                <this.CalendaryTop />
                <MonthComponent {...this.props} {...this.state} min={this.min} max={this.max} selectDay={this.handleSelectDay}/>
                <input type="hidden" key={this.state.selected} name={this.name} {...(this.state.selected !== undefined ? {value: DateLib.StringDateForm(this.state.selected)} : {})} />
            </div>
        )
    }
}

export { CalendaryMonth }